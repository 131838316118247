import React, { useEffect, useState } from "react";
import { graphql, Link, PageProps } from "gatsby";
import SEO from "../../seo";
import { ShopPageDataType } from "./types";
import { ProductCategoryPageContextType } from "../category/types";
import ProductsRows from "../../products-rows";
import Layout from "../../layout";
import { getNonEmptyCategories } from "../../../helpers/data/categories";
import Modal from "../../modal";
import { HeaderQueryDataType } from "../../footer/types";
import Pagination from "../../pagination";
import NoUiSlider from "nouislider";

import "nouislider/dist/nouislider.min.css";
import "choices.js/public/assets/styles/choices.min.css";
import "../../../css/style-default.css";

export default function Shop({ data, pageContext }: PageProps<ShopPageDataType, Pick<ProductCategoryPageContextType, "numOfPages" | "currentPageIndex">>) {
  let {
    buildMetadata: {
      buildTime
    },
    productsList: {
      edges: products
    },
    productCategories,
  } = data;
  const [prods, setProds] = useState(products);
  const { numOfPages, currentPageIndex } = pageContext;
  const productDates = {
    first_publication_date: buildTime,
    last_publication_date: buildTime,
  };
  const usedCategories = getNonEmptyCategories(productCategories, { edges: products } as HeaderQueryDataType["products"]);
  const onSortProduct = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    let sortedProducts = [];

    if (value === "popularity") {
      sortedProducts = [...products].sort((a, b) => {
        return Number(b.node.total_sales) > Number(a.node.total_sales) ? 1 : -1;
      });
    } else if (value === "high-low") {
      sortedProducts = [...products].sort((a, b) => {
        return Number(a.node.regular_price) > Number(b.node.regular_price) ? -1 : 1;
      });
    } else if (value === "low-high") {
      sortedProducts = [...products].sort((a, b) => {
        return Number(a.node.regular_price) > Number(b.node.regular_price) ? 1 : -1;
      });
    } else {
      sortedProducts = products;
    }
    setProds(sortedProducts);
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      const range = document.getElementById('range');
      const slider = NoUiSlider.create(range as HTMLElement, {
        range: {
          "min": 50,
          "max": 100_000
        },
        step: 5,
        start: [50, 60_000],
        margin: 50,
        connect: true,
        direction: "ltr",
        orientation: "horizontal",
        behaviour: "tap-drag",
        tooltips: true,
        format: {
          to: function (value) {
            return parseInt(`${value}`, 10) + 'DZD';
          },
          from: function (value) {
            return parseInt(value.replace('', ''), 10);
          }
        },
      });
      slider.on("end", ([min, max]) => {
        min = String(min).replace("DZD", "");
        max = String(max).replace("DZD", "");
        min = parseInt(min, 10);
        max = parseInt(max, 10);
        let filtredProducts = prods.filter(({ node: { regular_price } }) => {
          regular_price = Number(regular_price);
          if (regular_price >= min && regular_price <= max) {
            return true;
          } return false;
        });
        setProds(filtredProducts);
      });
    }
  }, []);
  useEffect(() => {
    function injectClassess(x: any) {
      let pickerCustomClass = x.dataset.customclass;
      let pickerSevClasses = pickerCustomClass.split(' ');
      x.parentElement.classList.add.apply(x.parentElement.classList, pickerSevClasses);
    }

    const selectPicker = document.querySelectorAll('.selectpicker');
    import("choices.js")
      .then(({ default: Choices }) => {
        if (selectPicker.length) {
          selectPicker.forEach((el) => {
            new Choices(el, {
              placeholder: true,
              searchEnabled: false,
              itemSelectText: '',
              callbackOnInit: () => injectClassess(el),
            });
          });
        }
      });
  }, []);

  return (
    <Layout>
      <SEO
        title={"Boutique"}
        description={products?.[0].node.name}
        banner={products?.[0]?.node?.images?.[0]?.src || ""}
        pathname={`/shop/`}
        node={productDates}
      />
      <div className={"container"}>

        {/* <!--  Modal --> */}
        <Modal />

        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">Boutique</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to="/">Accueil</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Boutique</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container p-0">
            <div className="row">
              {/* <!-- SHOP SIDEBAR --> */}
              <div className="col-lg-3 order-2 order-lg-1">
                <h5 className="text-uppercase mb-4">Filtre par prix</h5>
                <div className="price-range pt-4 mb-4">
                  <div id="range"></div>
                  <div className="row pt-2">
                    <div className="col-6">
                      <strong className="small fw-bold text-uppercase">De</strong>
                    </div>
                    <div className="col-6 text-end">
                      <strong className="small fw-bold text-uppercase">à</strong>
                    </div>
                  </div>
                </div>
                <h5 className="text-uppercase mb-4">catégories</h5>
                <ul className="list-unstyled small text-muted ps-lg-4 font-weight-normal">
                  {
                    usedCategories.map(({ node: category }) => (
                      <li
                        className="mb-2 first-letter__text_capitalize"
                        key={`categories-filter-${category.slug}`}
                      >
                        <Link className="reset-anchor" to={`/category/${category.slug}/`}>
                          {category.slug}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              {/* <!-- SHOP LISTING --> */}
              <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">

                <div className="row mb-3 align-items-center">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <p className="text-sm text-muted mb-0">Affichage de {Number(currentPageIndex || 0) + 1} de tous les résultat</p>
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-inline d-flex align-items-center justify-content-lg-end mb-0">
                      <li className="list-inline-item text-muted me-3"><a className="reset-anchor p-0" href="#!"><i className="fas fa-th-large"></i></a></li>
                      <li className="list-inline-item text-muted me-3"><a className="reset-anchor p-0" href="#!"><i className="fas fa-th"></i></a></li>
                      <li className="list-inline-item">
                        <select
                          className="selectpicker"
                          data-customclass="form-control form-control-sm"
                          onChange={onSortProduct}
                        >
                          <option value>Trier par</option>
                          <option value="default">Tri par défaut</option>
                          <option value="popularity">Popularité</option>
                          <option value="low-high">Prix ​​croissant</option>
                          <option value="high-low">Prix dé​​croissant</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- PRODUCTS --> */}
                <ProductsRows
                  products={prods}
                  columnClassName={"col-lg-4 col-sm-6"}
                />
                {/* <!--- PAGINATION --> */}
                <Pagination
                  numOfPages={numOfPages}
                  currentPageIndex={currentPageIndex || 0}
                  mainPath={`/shop`}
                />

              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($limit: Int!, $skip: Int!) {
    buildMetadata: siteBuildMetadata {
      buildTime(formatString: "YYYY-MM-DD")
    }
    productsList: allWcProducts(
      limit: $limit,
			skip: $skip
    ) {
      totalCount
      edges {
        node {
          wordpress_id
          slug
          name
          regular_price
          price
          description
          category_ids
          total_sales
          images {
            src
            width
            height
            is_resized
          }
        }
      }
    }
    productCategories: allWcProductsCategories(filter: {count: {gt: 0}}) {
      edges {
        node {
          term_id
          slug
          name
        }
      }
    }
  }
`;